import { default as indexQmeS472sJIMeta } from "/home/node/client/.firstvet/pages/index.vue?macro=true";
import { default as _91_91slug_93_934tdfrW3ROpMeta } from "/home/node/client/.firstvet/pages/[[slug]].vue?macro=true";
import { default as _91_91slug_93_93meAR83q3WRMeta } from "/home/node/client/.firstvet/pages/app/[[slug]].vue?macro=true";
import { default as cookies3TmnwgVt0aMeta } from "/home/node/client/.firstvet/pages/app/cookies.vue?macro=true";
import { default as indexvqou7OL24yMeta } from "/home/node/client/.firstvet/pages/app/ppcontacts/index.vue?macro=true";
import { default as _91_91slug_93_93v7BY6WWw5mMeta } from "/home/node/client/.firstvet/pages/articles/[[slug]].vue?macro=true";
import { default as _91_91slug_93_93noza1PpULmMeta } from "/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue?macro=true";
import { default as _91_46_46_46slug_93tyaMd9oEZgMeta } from "/home/node/client/.firstvet/pages/articles/author/[...slug].vue?macro=true";
import { default as indexNSBQ4DrXtJMeta } from "/home/node/client/.firstvet/pages/articles/author/index.vue?macro=true";
import { default as indexSj0gN9ToG9Meta } from "/home/node/client/.firstvet/pages/articles/index.vue?macro=true";
import { default as _91_91slug_93_93vcVBWlzeXiMeta } from "/home/node/client/.firstvet/pages/city/[[slug]].vue?macro=true";
import { default as indexCKG1JbZsRBMeta } from "/home/node/client/.firstvet/pages/cookies/index.vue?macro=true";
import { default as country_45selectRUZ8OV9dL5Meta } from "/home/node/client/pages/country-select.vue?macro=true";
import { default as _91_91slug_93_93zxN0AQPn0qMeta } from "/home/node/client/.firstvet/pages/insurance/[[slug]].vue?macro=true";
import { default as indexrGGK5c7L15Meta } from "/home/node/client/.firstvet/pages/insurance/index.vue?macro=true";
import { default as _91_91slug_93_93Wki3kTUIcEMeta } from "/home/node/client/.firstvet/pages/jobs/[[slug]].vue?macro=true";
import { default as indexsI7gVdPwDlMeta } from "/home/node/client/.firstvet/pages/jobs/index.vue?macro=true";
import { default as _91_91slug_93_935ZPROMWTytMeta } from "/home/node/client/.firstvet/pages/partner/[[slug]].vue?macro=true";
import { default as index1I6ece1RfXMeta } from "/home/node/client/.firstvet/pages/partner/index.vue?macro=true";
import { default as indexkw7BFYJcRJMeta } from "/home/node/client/.firstvet/pages/ppcontacts/index.vue?macro=true";
import { default as indexYFq9PSOw4PMeta } from "/home/node/client/.firstvet/pages/ppja/index.vue?macro=true";
import { default as indexYMkMgv9Sb8Meta } from "/home/node/client/.firstvet/pages/press/index.vue?macro=true";
import { default as _91_91slug_93_93EAk2oHQDjIMeta } from "/home/node/client/.firstvet/pages/press/pr/[[slug]].vue?macro=true";
import { default as pricOJhDGaSfMeta } from "/home/node/client/.firstvet/pages/press/pr/pr.vue?macro=true";
import { default as indexHKSv4Uf9U3Meta } from "/home/node/client/.firstvet/pages/privacy-policy/index.vue?macro=true";
import { default as _91_91slug_93_93kR4dFt4x9xMeta } from "/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue?macro=true";
import { default as indexCbu0Vwv5dAMeta } from "/home/node/client/.firstvet/pages/questions/index.vue?macro=true";
import { default as _91_46_46_46slug_93mSs9Smo4WhMeta } from "/home/node/client/pages/shop/[...slug].vue?macro=true";
import { default as indexXFHzzTqn00Meta } from "/home/node/client/pages/shop/advent-calendar/index.vue?macro=true";
import { default as _91_46_46_46slug_93F0vGeFjjkGMeta } from "/home/node/client/pages/shop/brands/[...slug].vue?macro=true";
import { default as indexaRZzOQuEw3Meta } from "/home/node/client/pages/shop/brands/index.vue?macro=true";
import { default as _91_46_46_46slug_930OZamWOekBMeta } from "/home/node/client/pages/shop/campaigns/[...slug].vue?macro=true";
import { default as _91animalType_93JIT39z5rPOMeta } from "/home/node/client/pages/shop/campaigns/black-week/[animalType].vue?macro=true";
import { default as indexB5pyBKNhFeMeta } from "/home/node/client/pages/shop/campaigns/black-week/index.vue?macro=true";
import { default as indexs9y6zoe1NiMeta } from "/home/node/client/pages/shop/campaigns/index.vue?macro=true";
import { default as checkoutXiXOBYjJz7Meta } from "/home/node/client/pages/shop/checkout.vue?macro=true";
import { default as indexSF2jPFF0YxMeta } from "/home/node/client/pages/shop/dashboard/account-settings/index.vue?macro=true";
import { default as personal_45informationK1lrgxJGRMMeta } from "/home/node/client/pages/shop/dashboard/account-settings/personal-information.vue?macro=true";
import { default as _91id_93ziX0D1pBrVMeta } from "/home/node/client/pages/shop/dashboard/order-history/[id].vue?macro=true";
import { default as index8vj6lGFTH8Meta } from "/home/node/client/pages/shop/dashboard/order-history/index.vue?macro=true";
import { default as prescriptionsQe26GYsy7GMeta } from "/home/node/client/pages/shop/dashboard/prescriptions.vue?macro=true";
import { default as returnsB7TT9o8xldMeta } from "/home/node/client/pages/shop/dashboard/returns.vue?macro=true";
import { default as _91id_933uJOlwtu77Meta } from "/home/node/client/pages/shop/dashboard/subscriptions/[id].vue?macro=true";
import { default as indexzuRYw9xbQDMeta } from "/home/node/client/pages/shop/dashboard/subscriptions/index.vue?macro=true";
import { default as dashboardbeOZBhndB4Meta } from "/home/node/client/pages/shop/dashboard.vue?macro=true";
import { default as favoritesbKhaaTBmfxMeta } from "/home/node/client/pages/shop/favorites.vue?macro=true";
import { default as food_45consultationonyIXKSevZMeta } from "/home/node/client/pages/shop/food-consultation.vue?macro=true";
import { default as health_45checkpdU3eIDtRTMeta } from "/home/node/client/pages/shop/health-check.vue?macro=true";
import { default as indextobIe28DMUMeta } from "/home/node/client/pages/shop/index.vue?macro=true";
import { default as _91_46_46_46slug_93eiB3AJYl2eMeta } from "/home/node/client/pages/shop/info/[...slug].vue?macro=true";
import { default as indexJEMBUpuFjLMeta } from "/home/node/client/pages/shop/info/index.vue?macro=true";
import { default as loginRiyJwdfslsMeta } from "/home/node/client/pages/shop/login.vue?macro=true";
import { default as order_45returnuDQiAvkmEeMeta } from "/home/node/client/pages/shop/order-return.vue?macro=true";
import { default as parasite_45testsnCsFaM7YlsMeta } from "/home/node/client/.firstvet/pages/shop/parasite-tests.vue?macro=true";
import { default as _91_46_46_46slug_93qetc8SIO1oMeta } from "/home/node/client/pages/shop/prescription/[brand]/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93h6whS9ooitMeta } from "/home/node/client/pages/shop/product/[...slug].vue?macro=true";
import { default as receiptKOmvqH7SylMeta } from "/home/node/client/pages/shop/receipt.vue?macro=true";
import { default as recommended_45productsl4SJFm3O2lMeta } from "/home/node/client/pages/shop/recommended-products.vue?macro=true";
import { default as searchmBXBBJA9x8Meta } from "/home/node/client/pages/shop/search.vue?macro=true";
import { default as sitemapWOENLFgwrXMeta } from "/home/node/client/.firstvet/pages/sitemap.vue?macro=true";
import { default as index5cfFJ2KABbMeta } from "/home/node/client/.firstvet/pages/symptom/index.vue?macro=true";
import { default as indexsHv6tmKpEWMeta } from "/home/node/client/.firstvet/pages/terms-conditions/index.vue?macro=true";
import { default as component_45stubAxpbWaZuQ0Meta } from "/home/node/client/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubAxpbWaZuQ0 } from "/home/node/client/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    meta: indexQmeS472sJIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___dk",
    path: "/dk/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "slug___uk",
    path: "/uk/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "slug___us",
    path: "/us/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "slug___fi",
    path: "/fi/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "slug___no",
    path: "/no/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "slug___sv",
    path: "/sv/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "app-slug___dk",
    path: "/dk/app/:slug()",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "app-slug___de",
    path: "/de/app/:slug()",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "app-slug___uk",
    path: "/uk/app/:slug()",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "app-slug___us",
    path: "/us/app/:slug()",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "app-slug___fi",
    path: "/fi/app/:slug()",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "app-slug___no",
    path: "/no/app/:slug()",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "app-slug___sv",
    path: "/sv/apps/:slug()",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "app-cookies___dk",
    path: "/dk/app/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: "app-cookies___de",
    path: "/de/app/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: "app-cookies___uk",
    path: "/uk/app/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: "app-cookies___us",
    path: "/us/app/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: "app-cookies___fi",
    path: "/fi/app/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: "app-cookies___no",
    path: "/no/app/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: "app-cookies___sv",
    path: "/sv/apps/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: "app-ppcontacts___dk",
    path: "/dk/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "app-ppcontacts___de",
    path: "/de/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "app-ppcontacts___uk",
    path: "/uk/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "app-ppcontacts___us",
    path: "/us/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "app-ppcontacts___fi",
    path: "/fi/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "app-ppcontacts___no",
    path: "/no/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "app-ppcontacts___sv",
    path: "/sv/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-slug___dk",
    path: "/dk/artikler/:slug()",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-slug___de",
    path: "/de/artikel/:slug()",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-slug___uk",
    path: "/uk/articles/:slug()",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-slug___us",
    path: "/us/articles/:slug()",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-slug___fi",
    path: "/fi/artikkeleita/:slug()",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-slug___no",
    path: "/no/artikler/:slug()",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-slug___sv",
    path: "/sv/artiklar/:slug()",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-animal-slug___dk",
    path: "/dk/artikler/dyr/:slug()",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-animal-slug___de",
    path: "/de/artikel/tier/:slug()",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-animal-slug___uk",
    path: "/uk/articles/animal/:slug()",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-animal-slug___us",
    path: "/us/articles/animal/:slug()",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-animal-slug___fi",
    path: "/fi/artikkeleita/elain/:slug()",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-animal-slug___no",
    path: "/no/artikler/dyr/:slug()",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-animal-slug___sv",
    path: "/sv/artiklar/djur/:slug()",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "articles-author-slug___dk",
    path: "/dk/artikler/forfatter/:slug()",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-author-slug___de",
    path: "/de/artikel/autoren/:slug()",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-author-slug___uk",
    path: "/uk/articles/author/:slug()",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-author-slug___us",
    path: "/us/articles/author/:slug()",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-author-slug___fi",
    path: "/fi/artikkeleita/kirjoittaja/:slug()",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-author-slug___no",
    path: "/no/artikler/forfatter/:slug()",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-author-slug___sv",
    path: "/sv/artiklar/skribent/:slug()",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "articles-author___dk",
    path: "/dk/artikler/forfatter",
    meta: indexNSBQ4DrXtJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-author___de",
    path: "/de/artikel/autoren",
    meta: indexNSBQ4DrXtJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-author___uk",
    path: "/uk/articles/author",
    meta: indexNSBQ4DrXtJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-author___us",
    path: "/us/articles/author",
    meta: indexNSBQ4DrXtJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-author___fi",
    path: "/fi/artikkeleita/kirjoittaja",
    meta: indexNSBQ4DrXtJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-author___no",
    path: "/no/artikler/forfatter",
    meta: indexNSBQ4DrXtJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-author___sv",
    path: "/sv/artiklar/skribent",
    meta: indexNSBQ4DrXtJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: "articles___dk",
    path: "/dk/artikler",
    meta: indexSj0gN9ToG9Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "articles___de",
    path: "/de/artikel",
    meta: indexSj0gN9ToG9Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "articles___uk",
    path: "/uk/articles",
    meta: indexSj0gN9ToG9Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "articles___us",
    path: "/us/articles",
    meta: indexSj0gN9ToG9Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "articles___fi",
    path: "/fi/artikkeleita",
    meta: indexSj0gN9ToG9Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "articles___no",
    path: "/no/artikler",
    meta: indexSj0gN9ToG9Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "articles___sv",
    path: "/sv/artiklar",
    meta: indexSj0gN9ToG9Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "city-slug___dk",
    path: "/dk/by/:slug()",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "city-slug___de",
    path: "/de/stadt/:slug()",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "city-slug___uk",
    path: "/uk/city/:slug()",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "city-slug___us",
    path: "/us/city/:slug()",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "city-slug___fi",
    path: "/fi/kaupunki/:slug()",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "city-slug___no",
    path: "/no/by/:slug()",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "city-slug___sv",
    path: "/sv/stad/:slug()",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "cookies___dk",
    path: "/dk/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: "cookies___de",
    path: "/de/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: "cookies___uk",
    path: "/uk/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: "cookies___us",
    path: "/us/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: "cookies___fi",
    path: "/fi/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: "cookies___no",
    path: "/no/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: "cookies___sv",
    path: "/sv/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: "country-select",
    path: "/country-select",
    meta: country_45selectRUZ8OV9dL5Meta || {},
    component: () => import("/home/node/client/pages/country-select.vue").then(m => m.default || m)
  },
  {
    name: "index___sv",
    path: "/sv",
    meta: indexQmeS472sJIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___uk",
    path: "/uk",
    meta: indexQmeS472sJIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___dk",
    path: "/dk",
    meta: indexQmeS472sJIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexQmeS472sJIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___us",
    path: "/us",
    meta: indexQmeS472sJIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fi",
    path: "/fi",
    meta: indexQmeS472sJIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___no",
    path: "/no",
    meta: indexQmeS472sJIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "insurance-slug___dk",
    path: "/dk/forsikring/:slug()",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "insurance-slug___de",
    path: "/de/versicherung/:slug()",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "insurance-slug___uk",
    path: "/uk/insurance/:slug()",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "insurance-slug___us",
    path: "/us/insurance/:slug()",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "insurance-slug___fi",
    path: "/fi/vakuutus/:slug()",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "insurance-slug___no",
    path: "/no/forsikring/:slug()",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "insurance-slug___sv",
    path: "/sv/forsakring/:slug()",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "insurance___dk",
    path: "/dk/forsikring",
    meta: indexrGGK5c7L15Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: "insurance___de",
    path: "/de/versicherung",
    meta: indexrGGK5c7L15Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: "insurance___uk",
    path: "/uk/insurance",
    meta: indexrGGK5c7L15Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: "insurance___us",
    path: "/us/insurance",
    meta: indexrGGK5c7L15Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: "insurance___fi",
    path: "/fi/vakuutus",
    meta: indexrGGK5c7L15Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: "insurance___no",
    path: "/no/forsikring",
    meta: indexrGGK5c7L15Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: "insurance___sv",
    path: "/sv/forsakring",
    meta: indexrGGK5c7L15Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___dk",
    path: "/dk/jobs/:slug()",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___de",
    path: "/de/jobs/:slug()",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___uk",
    path: "/uk/jobs/:slug()",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___us",
    path: "/us/jobs/:slug()",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___fi",
    path: "/fi/jobs/:slug()",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___no",
    path: "/no/jobs/:slug()",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "jobs-slug___sv",
    path: "/sv/jobs/:slug()",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "jobs___dk",
    path: "/dk/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs___de",
    path: "/de/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs___uk",
    path: "/uk/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs___us",
    path: "/us/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs___fi",
    path: "/fi/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs___no",
    path: "/no/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "jobs___sv",
    path: "/sv/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: "partner-slug___dk",
    path: "/dk/partners/:slug()",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "partner-slug___de",
    path: "/de/versicherungspartnern/:slug()",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "partner-slug___uk",
    path: "/uk/partners/:slug()",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "partner-slug___us",
    path: "/us/partners/:slug()",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "partner-slug___fi",
    path: "/fi/yhteisty%C3%B6kumppanit/:slug()",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "partner-slug___no",
    path: "/no/partners/:slug()",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "partner-slug___sv",
    path: "/sv/partners/:slug()",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "partner___dk",
    path: "/dk/partners",
    meta: index1I6ece1RfXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "partner___de",
    path: "/de/versicherungspartnern",
    meta: index1I6ece1RfXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "partner___uk",
    path: "/uk/partners",
    meta: index1I6ece1RfXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "partner___us",
    path: "/us/partners",
    meta: index1I6ece1RfXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "partner___fi",
    path: "/fi/yhteisty%C3%B6kumppanit",
    meta: index1I6ece1RfXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "partner___no",
    path: "/no/partners",
    meta: index1I6ece1RfXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "partner___sv",
    path: "/sv/partners",
    meta: index1I6ece1RfXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: "ppcontacts___dk",
    path: "/dk/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "ppcontacts___de",
    path: "/de/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "ppcontacts___uk",
    path: "/uk/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "ppcontacts___us",
    path: "/us/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "ppcontacts___fi",
    path: "/fi/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "ppcontacts___no",
    path: "/no/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "ppcontacts___sv",
    path: "/sv/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: "ppja___dk",
    path: "/dk/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: "ppja___de",
    path: "/de/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: "ppja___uk",
    path: "/uk/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: "ppja___us",
    path: "/us/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: "ppja___fi",
    path: "/fi/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: "ppja___no",
    path: "/no/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: "ppja___sv",
    path: "/sv/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: "press___dk",
    path: "/dk/press",
    meta: indexYMkMgv9Sb8Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "press___de",
    path: "/de/press",
    meta: indexYMkMgv9Sb8Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "press___uk",
    path: "/uk/press",
    meta: indexYMkMgv9Sb8Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "press___us",
    path: "/us/press",
    meta: indexYMkMgv9Sb8Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "press___fi",
    path: "/fi/press",
    meta: indexYMkMgv9Sb8Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "press___no",
    path: "/no/press",
    meta: indexYMkMgv9Sb8Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "press___sv",
    path: "/sv/press",
    meta: indexYMkMgv9Sb8Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: "press-pr-slug___dk",
    path: "/dk/press/pr/:slug()",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "press-pr-slug___de",
    path: "/de/press/pr/:slug()",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "press-pr-slug___uk",
    path: "/uk/press/pr/:slug()",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "press-pr-slug___us",
    path: "/us/press/pr/:slug()",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "press-pr-slug___fi",
    path: "/fi/press/pr/:slug()",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "press-pr-slug___no",
    path: "/no/press/pr/:slug()",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "press-pr-slug___sv",
    path: "/sv/press/pr/:slug()",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "press-pr-pr___dk",
    path: "/dk/press/pr",
    meta: pricOJhDGaSfMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: "press-pr-pr___de",
    path: "/de/press/pr",
    meta: pricOJhDGaSfMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: "press-pr-pr___uk",
    path: "/uk/press/pr",
    meta: pricOJhDGaSfMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: "press-pr-pr___us",
    path: "/us/press/pr",
    meta: pricOJhDGaSfMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: "press-pr-pr___fi",
    path: "/fi/press/pr",
    meta: pricOJhDGaSfMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: "press-pr-pr___no",
    path: "/no/press/pr",
    meta: pricOJhDGaSfMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: "press-pr-pr___sv",
    path: "/sv/press/pr",
    meta: pricOJhDGaSfMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___dk",
    path: "/dk/privatlivspolitik",
    meta: indexHKSv4Uf9U3Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___de",
    path: "/de/datenschutz-bestimmungen",
    meta: indexHKSv4Uf9U3Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___uk",
    path: "/uk/privacy-policy",
    meta: indexHKSv4Uf9U3Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___us",
    path: "/us/privacy-policy",
    meta: indexHKSv4Uf9U3Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___fi",
    path: "/fi/tietosuojakaytanto",
    meta: indexHKSv4Uf9U3Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___no",
    path: "/no/personvernpolicy",
    meta: indexHKSv4Uf9U3Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___sv",
    path: "/sv/integritetspolicy",
    meta: indexHKSv4Uf9U3Meta || {},
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "questions-id-slug___dk",
    path: "/dk/sporgsmal/\\:id/:slug()",
    meta: _91_91slug_93_93kR4dFt4x9xMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "questions-id-slug___de",
    path: "/de/fragen/\\:id/:slug()",
    meta: _91_91slug_93_93kR4dFt4x9xMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "questions-id-slug___uk",
    path: "/uk/questions/\\:id/:slug()",
    meta: _91_91slug_93_93kR4dFt4x9xMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "questions-id-slug___fi",
    path: "/fi/kysymykset/\\:id/:slug()",
    meta: _91_91slug_93_93kR4dFt4x9xMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "questions-id-slug___no",
    path: "/no/sporsmal/\\:id/:slug()",
    meta: _91_91slug_93_93kR4dFt4x9xMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "questions-id-slug___sv",
    path: "/sv/fragor/\\:id/:slug()",
    meta: _91_91slug_93_93kR4dFt4x9xMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "questions___dk",
    path: "/dk/sporgsmal",
    meta: indexCbu0Vwv5dAMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: "questions___de",
    path: "/de/fragen",
    meta: indexCbu0Vwv5dAMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: "questions___uk",
    path: "/uk/questions",
    meta: indexCbu0Vwv5dAMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: "questions___fi",
    path: "/fi/kysymykset",
    meta: indexCbu0Vwv5dAMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: "questions___no",
    path: "/no/sporsmal",
    meta: indexCbu0Vwv5dAMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: "questions___sv",
    path: "/sv/fragor",
    meta: indexCbu0Vwv5dAMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-slug___sv",
    path: "/sv/butik/:slug(.*)*",
    component: () => import("/home/node/client/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-slug___en",
    path: "/en/shop/:slug(.*)*",
    component: () => import("/home/node/client/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-advent-calendar___sv",
    path: "/sv/butik/djurkalender",
    meta: indexXFHzzTqn00Meta || {},
    component: () => import("/home/node/client/pages/shop/advent-calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-brands-slug___sv",
    path: "/sv/butik/varumarken/:slug()",
    component: () => import("/home/node/client/pages/shop/brands/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-brands-slug___en",
    path: "/en/shop/brands/:slug()",
    component: () => import("/home/node/client/pages/shop/brands/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-brands___sv",
    path: "/sv/butik/varumarken",
    component: () => import("/home/node/client/pages/shop/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-brands___en",
    path: "/en/shop/brands",
    component: () => import("/home/node/client/pages/shop/brands/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-campaigns-slug___sv",
    path: "/sv/butik/kampanjer/:slug()",
    component: () => import("/home/node/client/pages/shop/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-campaigns-slug___en",
    path: "/en/shop/campaigns/:slug()",
    component: () => import("/home/node/client/pages/shop/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-campaigns-black-week-animalType___sv",
    path: "/sv/butik/kampanjer/black-week/\\:animalType",
    component: () => import("/home/node/client/pages/shop/campaigns/black-week/[animalType].vue").then(m => m.default || m)
  },
  {
    name: "shop-campaigns-black-week___sv",
    path: "/sv/butik/kampanjer/black-week",
    meta: indexB5pyBKNhFeMeta || {},
    component: () => import("/home/node/client/pages/shop/campaigns/black-week/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-campaigns___sv",
    path: "/sv/butik/kampanjer",
    component: () => import("/home/node/client/pages/shop/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-campaigns___en",
    path: "/en/shop/campaigns",
    component: () => import("/home/node/client/pages/shop/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-checkout___sv",
    path: "/sv/butik/kassa",
    meta: checkoutXiXOBYjJz7Meta || {},
    component: () => import("/home/node/client/pages/shop/checkout.vue").then(m => m.default || m)
  },
  {
    name: "shop-checkout___en",
    path: "/en/shop/checkout",
    meta: checkoutXiXOBYjJz7Meta || {},
    component: () => import("/home/node/client/pages/shop/checkout.vue").then(m => m.default || m)
  },
  {
    name: "shop-dashboard___sv",
    path: "/sv/butik/mina-sidor",
    meta: dashboardbeOZBhndB4Meta || {},
    component: () => import("/home/node/client/pages/shop/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "shop-dashboard-account-settings___sv",
    path: "kontoinstallningar",
    component: () => import("/home/node/client/pages/shop/dashboard/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-dashboard-account-settings-personal-information___sv",
    path: "kontoinstallningar/personuppgifter",
    component: () => import("/home/node/client/pages/shop/dashboard/account-settings/personal-information.vue").then(m => m.default || m)
  },
  {
    name: "shop-dashboard-order-history-id___sv",
    path: "orderhistorik/\\:id",
    component: () => import("/home/node/client/pages/shop/dashboard/order-history/[id].vue").then(m => m.default || m)
  },
  {
    name: "shop-dashboard-order-history___sv",
    path: "orderhistorik",
    component: () => import("/home/node/client/pages/shop/dashboard/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-dashboard-prescriptions___sv",
    path: "recept",
    meta: prescriptionsQe26GYsy7GMeta || {},
    component: () => import("/home/node/client/pages/shop/dashboard/prescriptions.vue").then(m => m.default || m)
  },
  {
    name: "shop-dashboard-returns___sv",
    path: "returer",
    component: () => import("/home/node/client/pages/shop/dashboard/returns.vue").then(m => m.default || m)
  },
  {
    name: "shop-dashboard-subscriptions-id___sv",
    path: "prenumerationer/\\:id",
    component: () => import("/home/node/client/pages/shop/dashboard/subscriptions/[id].vue").then(m => m.default || m)
  },
  {
    name: "shop-dashboard-subscriptions___sv",
    path: "prenumerationer",
    component: () => import("/home/node/client/pages/shop/dashboard/subscriptions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "shop-favorites___sv",
    path: "/sv/butik/favorites",
    component: () => import("/home/node/client/pages/shop/favorites.vue").then(m => m.default || m)
  },
  {
    name: "shop-food-consultation___sv",
    path: "/sv/butik/foderradgivning",
    component: () => import("/home/node/client/pages/shop/food-consultation.vue").then(m => m.default || m)
  },
  {
    name: "shop-health-check___sv",
    path: "/sv/butik/health-check",
    meta: health_45checkpdU3eIDtRTMeta || {},
    component: () => import("/home/node/client/pages/shop/health-check.vue").then(m => m.default || m)
  },
  {
    name: "shop-health-check___en",
    path: "/en/shop/health-check",
    meta: health_45checkpdU3eIDtRTMeta || {},
    component: () => import("/home/node/client/pages/shop/health-check.vue").then(m => m.default || m)
  },
  {
    name: "shop___sv",
    path: "/sv/butik",
    meta: indextobIe28DMUMeta || {},
    component: () => import("/home/node/client/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___en",
    path: "/en/shop",
    meta: indextobIe28DMUMeta || {},
    component: () => import("/home/node/client/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-info-slug___sv",
    path: "/sv/butik/info/:slug()",
    meta: _91_46_46_46slug_93eiB3AJYl2eMeta || {},
    component: () => import("/home/node/client/pages/shop/info/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-info-slug___en",
    path: "/en/shop/info/:slug()",
    meta: _91_46_46_46slug_93eiB3AJYl2eMeta || {},
    component: () => import("/home/node/client/pages/shop/info/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-info___sv",
    path: "/sv/butik/info",
    meta: indexJEMBUpuFjLMeta || {},
    component: () => import("/home/node/client/pages/shop/info/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-info___en",
    path: "/en/shop/info",
    meta: indexJEMBUpuFjLMeta || {},
    component: () => import("/home/node/client/pages/shop/info/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-login___sv",
    path: "/sv/butik/logga-in",
    meta: loginRiyJwdfslsMeta || {},
    component: () => import("/home/node/client/pages/shop/login.vue").then(m => m.default || m)
  },
  {
    name: "shop-order-return___sv",
    path: "/sv/butik/returer",
    component: () => import("/home/node/client/pages/shop/order-return.vue").then(m => m.default || m)
  },
  {
    name: "shop-order-return___en",
    path: "/en/shop/order-return",
    component: () => import("/home/node/client/pages/shop/order-return.vue").then(m => m.default || m)
  },
  {
    name: "shop-parasite-tests___sv",
    path: "/sv/butik/parasitundersokningar",
    component: () => import("/home/node/client/.firstvet/pages/shop/parasite-tests.vue").then(m => m.default || m)
  },
  {
    name: "shop-prescription-brand-slug___sv",
    path: "/sv/butik/produkt/\\:brand/:slug()",
    meta: _91_46_46_46slug_93qetc8SIO1oMeta || {},
    component: () => import("/home/node/client/pages/shop/prescription/[brand]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-product-slug___sv",
    path: "/sv/butik/produkt/:slug()",
    meta: _91_46_46_46slug_93h6whS9ooitMeta || {},
    component: () => import("/home/node/client/pages/shop/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-product-slug___en",
    path: "/en/shop/product/:slug()",
    meta: _91_46_46_46slug_93h6whS9ooitMeta || {},
    component: () => import("/home/node/client/pages/shop/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "shop-receipt___sv",
    path: "/sv/butik/kvitto",
    component: () => import("/home/node/client/pages/shop/receipt.vue").then(m => m.default || m)
  },
  {
    name: "shop-receipt___en",
    path: "/en/shop/receipt",
    component: () => import("/home/node/client/pages/shop/receipt.vue").then(m => m.default || m)
  },
  {
    name: "shop-recommended-products___sv",
    path: "/sv/butik/rekommenderade-produkter",
    component: () => import("/home/node/client/pages/shop/recommended-products.vue").then(m => m.default || m)
  },
  {
    name: "shop-search___sv",
    path: "/sv/butik/sok",
    component: () => import("/home/node/client/pages/shop/search.vue").then(m => m.default || m)
  },
  {
    name: "shop-search___en",
    path: "/en/shop/search",
    component: () => import("/home/node/client/pages/shop/search.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___sv",
    path: "/sv/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___uk",
    path: "/uk/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___dk",
    path: "/dk/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___de",
    path: "/de/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___us",
    path: "/us/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___fi",
    path: "/fi/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "sitemap___no",
    path: "/no/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "symptom___dk",
    path: "/dk/symptomer",
    meta: index5cfFJ2KABbMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: "symptom___de",
    path: "/de/symptome",
    meta: index5cfFJ2KABbMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: "symptom___uk",
    path: "/uk/symptoms",
    meta: index5cfFJ2KABbMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: "symptom___us",
    path: "/us/symptoms",
    meta: index5cfFJ2KABbMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: "symptom___fi",
    path: "/fi/oireet",
    meta: index5cfFJ2KABbMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: "symptom___no",
    path: "/no/symptomer",
    meta: index5cfFJ2KABbMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: "symptom___sv",
    path: "/sv/symtom",
    meta: index5cfFJ2KABbMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions___dk",
    path: "/dk/brugervilkar",
    meta: indexsHv6tmKpEWMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions___de",
    path: "/de/nutzungsbedingungen",
    meta: indexsHv6tmKpEWMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions___uk",
    path: "/uk/terms-of-service",
    meta: indexsHv6tmKpEWMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions___us",
    path: "/us/terms-and-conditions",
    meta: indexsHv6tmKpEWMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions___fi",
    path: "/fi/kayttoehdot",
    meta: indexsHv6tmKpEWMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions___no",
    path: "/no/vilkar-for-bruk",
    meta: indexsHv6tmKpEWMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions___sv",
    path: "/sv/anvandarvillkor",
    meta: indexsHv6tmKpEWMeta || {},
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: "country-select",
    path: "/",
    component: () => import("/home/node/client/pages/country-select.vue").then(m => m.default || m)
  },
  {
    name: component_45stubAxpbWaZuQ0Meta?.name,
    path: "/ecom-assets/ecom2/js/PhleCom.min.js",
    component: component_45stubAxpbWaZuQ0
  },
  {
    name: component_45stubAxpbWaZuQ0Meta?.name,
    path: "/phl/demo/js/texts.js",
    component: component_45stubAxpbWaZuQ0
  },
  {
    name: component_45stubAxpbWaZuQ0Meta?.name,
    path: "/sv/butik/mina-recept",
    component: component_45stubAxpbWaZuQ0
  }
]