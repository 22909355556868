
// @ts-nocheck


export const localeCodes =  [
  "sv",
  "uk",
  "dk",
  "de",
  "us",
  "fi",
  "no"
]

export const localeLoaders = {
  "sv": [{ key: "../languages/sv-SE.ts", load: () => import("../languages/sv-SE.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_sv_SE_ts" */), cache: true }],
  "uk": [{ key: "../languages/en-GB.ts", load: () => import("../languages/en-GB.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_en_GB_ts" */), cache: true }],
  "dk": [{ key: "../languages/da-DK.ts", load: () => import("../languages/da-DK.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_da_DK_ts" */), cache: true }],
  "de": [{ key: "../languages/de-DE.ts", load: () => import("../languages/de-DE.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_de_DE_ts" */), cache: true }],
  "us": [{ key: "../languages/en-US.ts", load: () => import("../languages/en-US.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_en_US_ts" */), cache: true }],
  "fi": [{ key: "../languages/fi-FI.ts", load: () => import("../languages/fi-FI.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_fi_FI_ts" */), cache: true }],
  "no": [{ key: "../languages/nb-NO.ts", load: () => import("../languages/nb-NO.ts" /* webpackChunkName: "locale__home_node_client__firstvet_languages_nb_NO_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "name": "Sweden",
      "code": "sv",
      "language": "sv-SE",
      "dir": "ltr",
      "lang": "sv",
      "regionCode": "se",
      "files": [
        "/home/node/client/.firstvet/languages/sv-SE.ts"
      ]
    },
    {
      "name": "United Kingdom",
      "code": "uk",
      "language": "en-GB",
      "dir": "ltr",
      "lang": "en",
      "regionCode": "uk",
      "files": [
        "/home/node/client/.firstvet/languages/en-GB.ts"
      ]
    },
    {
      "name": "Denmark",
      "code": "dk",
      "language": "da-DK",
      "dir": "ltr",
      "lang": "da",
      "regionCode": "dk",
      "files": [
        "/home/node/client/.firstvet/languages/da-DK.ts"
      ]
    },
    {
      "name": "Germany",
      "code": "de",
      "language": "de-DE",
      "dir": "ltr",
      "lang": "de",
      "regionCode": "de",
      "files": [
        "/home/node/client/.firstvet/languages/de-DE.ts"
      ]
    },
    {
      "name": "United States",
      "code": "us",
      "language": "en-US",
      "dir": "ltr",
      "lang": "en",
      "regionCode": "us",
      "files": [
        "/home/node/client/.firstvet/languages/en-US.ts"
      ]
    },
    {
      "name": "Finland",
      "code": "fi",
      "language": "fi-FI",
      "dir": "ltr",
      "lang": "fi",
      "regionCode": "fi",
      "files": [
        "/home/node/client/.firstvet/languages/fi-FI.ts"
      ]
    },
    {
      "name": "Norway",
      "code": "no",
      "language": "nb-NO",
      "dir": "ltr",
      "lang": "nb",
      "regionCode": "no",
      "files": [
        "/home/node/client/.firstvet/languages/nb-NO.ts"
      ]
    }
  ],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "./languages/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://aws-ecom-1114-upgrade-nuxt.staging.firstvet.com",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "name": "Sweden",
    "code": "sv",
    "language": "sv-SE",
    "dir": "ltr",
    "lang": "sv",
    "regionCode": "se",
    "files": [
      {
        "path": "/home/node/client/.firstvet/languages/sv-SE.ts"
      }
    ]
  },
  {
    "name": "United Kingdom",
    "code": "uk",
    "language": "en-GB",
    "dir": "ltr",
    "lang": "en",
    "regionCode": "uk",
    "files": [
      {
        "path": "/home/node/client/.firstvet/languages/en-GB.ts"
      }
    ]
  },
  {
    "name": "Denmark",
    "code": "dk",
    "language": "da-DK",
    "dir": "ltr",
    "lang": "da",
    "regionCode": "dk",
    "files": [
      {
        "path": "/home/node/client/.firstvet/languages/da-DK.ts"
      }
    ]
  },
  {
    "name": "Germany",
    "code": "de",
    "language": "de-DE",
    "dir": "ltr",
    "lang": "de",
    "regionCode": "de",
    "files": [
      {
        "path": "/home/node/client/.firstvet/languages/de-DE.ts"
      }
    ]
  },
  {
    "name": "United States",
    "code": "us",
    "language": "en-US",
    "dir": "ltr",
    "lang": "en",
    "regionCode": "us",
    "files": [
      {
        "path": "/home/node/client/.firstvet/languages/en-US.ts"
      }
    ]
  },
  {
    "name": "Finland",
    "code": "fi",
    "language": "fi-FI",
    "dir": "ltr",
    "lang": "fi",
    "regionCode": "fi",
    "files": [
      {
        "path": "/home/node/client/.firstvet/languages/fi-FI.ts"
      }
    ]
  },
  {
    "name": "Norway",
    "code": "no",
    "language": "nb-NO",
    "dir": "ltr",
    "lang": "nb",
    "regionCode": "no",
    "files": [
      {
        "path": "/home/node/client/.firstvet/languages/nb-NO.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
