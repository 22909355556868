/**
 * These functions obfuscate/deobfuscate state URLs in the __NUXT_DATA__ variable to prevent
 * web crawlers from misinterpreting them as links and causing 404 errors in tools like Google's Search Console.
 * Transforming some JSON characters to Greek letters avoids false path recognition.
 */

export default definePayloadPlugin(() => {
  definePayloadReducer('SlugUrlObfuscator', (data) => {
    if (data && typeof data === 'object') {
      // Obfuscate the JSON string by replacing common JSON characters with Greek letters.
      const jsonString = JSON.stringify(data);
      const result = jsonString
        .replace(/\//g, 'β')
        .replace(/"/g, 'ε')
        .replace(/\}/g, 'ζ')
        .replace(/\{/g, 'λ')
        .replace(/,/g, 'ψ')
        .replace(/ /g, 'Σ')
        .replace(/:/g, 'π')
        .replace(/-/g, 'Ξ');
      return result;
    }
    return undefined;
  });

  definePayloadReviver('SlugUrlObfuscator', (data) => {
    if (typeof data === 'string') {
      // Deobfuscate the string by replacing Greek letters back to their original characters.
      const deobfuscatedString = data
        .replace(/β/g, '/')
        .replace(/ε/g, '"')
        .replace(/ζ/g, '}')
        .replace(/λ/g, '{')
        .replace(/ψ/g, ',')
        .replace(/Σ/g, ' ')
        .replace(/π/g, ':')
        .replace(/Ξ/g, '-');
      const parsedData = JSON.parse(deobfuscatedString);
      return parsedData;
    }
    return data;
  });
});
