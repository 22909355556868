const CATEGORY_AND_BRAND_COMBINATION_PURCHASE_LIMIT = {
  sv: {
    dev: [
      {
        brandIds: [
          52, // EverClean
          105, // FourFriends, (PrimaCat does not exist in dev)
        ],

        categoryIds: [425], // Catsand,
        limit: 4,
      },
      {
        brandIds: [
          59, // Monster (MUSH does not exist in dev)
        ],

        categoryIds: [561], // Frozen food
        limit: 1,
      },
    ],
    prod: [
      {
        brandIds: [
          245, // PrimaCat
          51, // EverClean
          114, // FourFriends
          113, // Intersand
        ],

        categoryIds: [290], // Catsand
        limit: 4,
      },
      {
        brandIds: [
          297, // MUSH
        ],

        categoryIds: [576], // Frozen food
        limit: 1,
      },
    ],
  },
};

export { CATEGORY_AND_BRAND_COMBINATION_PURCHASE_LIMIT };
